<template>

</template>

<script>
export default {
  data: () => ({

  }),


  created() {

  },

  methods: {
    showSafeInfo(){
      this.$router.push('/safeInfo')
    }

  },
}
</script>

<style lang="less" scoped>

</style>
